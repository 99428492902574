body {
  background-color: #DFD4BF;
  min-width: 400px;
}

.validation-error {
  border: 1px solid red;
}

.full-row {
  height: .01em;
}

.error-message {
  color: red;
  padding: .5em;
  height: .8em;
}

.info-message {
  color: green;
}

select:required:invalid {
  color: #666;
}

option[value=""][disabled] {
  display: none;
}

.banner {
  background-color: #DFD4BF;
  margin-bottom: -3px;    
  max-width: 100%;
  width: auto;
  overflow-x: hidden;
}

button {
  background: #AF7950;
  color: white;
  border: 0;
  padding: 0.5rem;
  border-radius: 5px;
  font-weight: bold;
}

button:disabled { 
  background: #DBAA8D;
  color: white;
  pointer-events: none;
}

button:hover { 
  background: #e0b636;
  cursor: pointer;
}

.center-content {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  /* margin-top: 5vh; */
}

.backdrop {
  display: flex;
  flex-direction: column;
  height: auto;
  font-family: Avenir, Arial, Helvetica, sans-serif;        
  padding: 0 4em 0 4em;
  background: linear-gradient(to bottom, #6371c7, #DFD4BF);
  border-radius: 5px;
}

.backdrop > .header {
  align-self: center;
  margin-top: 1em;
}

.backdrop > .header > h1 {
  font-size: xx-large;
  color: white;
  margin-bottom: 0em;
} 

.backdrop > .header > h2 {
  font-size: medium;
  color: white;
  margin-top: 0em;
  font-style: italic;
}

.backdrop > .header > .divider {
  font-size: xx-large;
  color: white;
  margin-left: 1em;
}

.backdrop > .header > button {
  display: flex;
  justify-content: right;
}

.grecaptcha-badge {
  bottom: 90px !important;
}

@media (max-width: 600px) {
  .backdrop {      
    padding: 0 2em 0 2em;
  }
  .backdrop > .header > h1 {
    margin: 0em;
  } 
}