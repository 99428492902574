  .center {
    text-align: center;
  }  
  
  .form-row > .info-message {
    height: 1rem;
    /* margin-bottom: 1rem; */
  }
  
  .white-background {
    background-color: white;
  }

  .form {
    display: flex;
    flex-direction: row;
    float: left;
    font-family: Avenir, Arial, Helvetica, sans-serif;        
    padding: 0 2rem 2rem 2rem;
    background-color: white;
    border-radius: 5px;
    margin: 0 1rem 0 0;
  }

  .form-wrapper {
    /* padding: 1em 2em 1em 2em; */
    background-color: rgb(244, 244, 244);
    color: #4E4E4E;
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-radius: 5px;
    width: 400px; 
  }

  .form-wrapper-collapsed {
    background-color: white;
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-radius: 5px;
    width: 50px;
  }
  
  .form-header {
    padding: 0.8rem;
    margin-bottom: 1em;
    background: white;
    color: #4E4E4E;
    font-weight: bold;
    border-radius: 5px 5px 0 0;
  }

  .form-header-collapsed {
    padding: 0.8rem 0.8rem .8rem 0;
    margin-bottom: 1em;
    background: white;
    color: #4E4E4E;
    font-weight: bold;
    border-radius: 5px 5px 0 0;
    /* border-bottom: solid 1px #cdccdf; */
  }
  
  .accordian {
    float: right;
    background-color: white;
    color: black;
    padding: .3em;
    margin-top: -.4em;
    border: solid 1px #cdccdf;
    border-radius: 5px;
    align-items: center;
    cursor: pointer;
  }
  
  .accordian-collapsed {
    float: right;
    background-color: white;
    padding: .3em;
    margin-top: -1.1em;
    border: solid 1px #cdccdf;
    border-radius: 5px;
    align-items: center;
    cursor: pointer;
  }
  
  .form-row-collapsed {
    display: flex;
    justify-content: flex-end;
    padding: 1.5em 3em 0em 3em;
  }  
  
  .form-header > label {
    padding-left: 0;
  }
  
  .form-info-message {
    display: flex;
    justify-content: flex-start;
    padding: 0 3em 0em 3em;
  }
  
  .form-row {
    display: flex;
    justify-content: flex-end;
    padding: 0.75em 2em 0em 2em;
    margin-top: .5em
  }
  
  .form-row > label {
    padding: 0.5em 0.5em 0 0;
    flex: 1;
    font-weight: bold;
    display: flex;
    justify-content: right;
    margin-right: 2em;
  }

  .form-row > .note {
    padding: 0.5em 0.5em 0 0;
    flex: 1;
    font-size: .9em;
  }

  .form-row > input {
    height: 2em;
  }
  
  .form-row > .form-fieldset {
    display: flex;
    justify-content: flex-start;
    flex: 4;
  }
  
  .form-row > .form-fieldset > input {
    height: 2em;
    flex-grow: 4;
  }

  .form-row > .form-fieldset > select {
    height: 2.2em;
    flex-grow: 4;
  }

  .form-row > .form-fieldset > .select {
    height: 2em;
    flex-grow: 4;
  }

  .form-row > .form-fieldset > textarea {
    height: 10em;
    resize: none;
    flex-grow: 4;
  }

  .form-row > .button-row {
    padding-right: 1em;
    align-self: end;
  }

  .form-row > .button-row > button {
    padding: 1em;
    margin-left: 0.5em;
  }

  .button-red {
    background: linear-gradient(#fb4410,#c2112f);
  }

  .agency-select {
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
  }

  .agency-select > button {
    margin-left: 2em;
  }
  
  @media (max-width: 900px) {
    .center-content{
      align-items: normal;
    }
    .form-row {
      flex-direction: column;
    }
    .form-row > label {
      justify-content: left;
    }
}
  