  .login-logo-top-second {
    height: 70px;
    width: 70px;
  }

  .login-logo-bottom {
    height: 50px;
    width: 50px;
  }

  .login-wrapper {
    display: flex;
    flex-direction: row;
    float: left;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 60vh;
    margin: 0 auto;
    padding: 2rem;
    background-color: white;
    border-radius: 5px;
  }
  
  .login-form {
    padding: 1em 2em 1em 2em;
    background-color: rgb(244, 244, 244);
    border-radius: 5px;
    font-family: Avenir, Arial, Helvetica, sans-serif;
  }

  .login-form > label {
    display: flex;
    justify-content: center;
    padding: 1rem;    
    font-size: 20px;
    font-weight: bold;
    width: 90%;
  }
  
  .login-form > input {
    padding: 1rem;
    border: 1px solid #999;
    margin-bottom: 1rem;
    border-radius: 5px;
    width: 90%;
  }
  
  .login-form > button {
    background: #AF7950;
    color: white;
    font-weight: bold;
    border: 0;
    padding: 1rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;
    width: 98%;
   }

   .login-form > button:hover { 
    background: #e0b636;
    cursor: pointer;
  }

   .login-form > .error-message {
     display: flex;
     justify-content: center;
   }
  
   .login-form > button:hover {
    cursor: pointer;
   }