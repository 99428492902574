.table {
    border-collapse: collapse;
    width: auto;
    font-family: Avenir, Arial, Helvetica, sans-serif;
    overflow-y: auto;
    display: block;
    /* margin: 1.5rem 0.5rem 0.5rem 0; */
    /* height: 70vh; */
}
  
.table-header {
    /* background: linear-gradient(#fb4410,#c2112f); */
    background: #6371c7;
    color: white;
    font-weight: bold;
    border-bottom: solid 1px #cdccdf;
    padding: 0.85rem;
    position: sticky;   
    top: 0;
    text-align: left;
}

.table-row {
    color: black;
    background: white;
    padding: 0.8rem;
}

.table-header:first-child { 
    border-radius: 5px 0 0 0; 
    border-left: none;
}

.table-header:last-child { 
    border-radius: 0 5px 0 0; 
    border-right: none;
}

.table-footer {
    /* background: linear-gradient(#fb4410,#6371c7); */
    background: #6371c7;
    color: white;
    font-weight: bold;
    border-bottom: solid 1px #cdccdf;
    align-items: center;
    text-align: center;
}

.table-footer:first-child { 
    border-radius: 0 0 5px 5px; 
    border-left: none;
    border-right: none;
}

.table-footer td {
    padding: 0.5rem;
}

.table-footer button {
    background: none;
    color: white;
    font-weight: bold;
}

.table-footer button:disabled { 
    background: none;
    color: lightgray;
}

tr:nth-child(even) td { 
    background-color: #d4dff3; 
} 

/* tr:last-child td:first-child {  
    border-radius: 0 0 0 5px;
    border-left: none;
}

tr:last-child td:last-child {  
    border-radius: 0 0 5px 0;
    border-right: none;
} */

.sort-icon {
    margin-left: .5rem;
}

.footer-section {
    margin: 0 2rem 0 2rem;
}