/* .admin-menu {
  border: 1px solid white;
  background-color: white;
  width: 200px;
  padding: 20px;
  margin: 20px;
  border-radius: 5px;
  color: black;
  cursor: pointer;
  font-family: Avenir, Arial, Helvetica, sans-serif;
} */

/* .admin-menu > .menu-item {
  padding: 0.25rem;
} */

.admin {
  margin: 0 1em 0 1em;
}

.header-bar {
  background-color: #6371c7;
  /* background: linear-gradient(to bottom, #6371c7, #DFD4BF); */
  padding: 1px;
  margin-bottom: 1em;  
}

.header-bar > h1 {
  color: white;
  font-size: x-large;
}