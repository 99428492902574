.footer {
  display: flex;
  justify-content: center;
  width: 100%;
  background: #DFD4BF;
  color: #4E4E4E;
  height: 120px;
  font-family: Avenir, Arial, Helvetica, sans-serif;
  font-size: 0.75rem;
}

.footer-border {
  align-self: center;
  margin-top: 20px;
  padding-top: 20px; 
  width: 95%;
  border-top: solid 1px #4E4E4E;
}

.copyright {
  float: left;
}

.hosted-by {
  float: right;
  margin-right: 10px;
  display: flex;
}

.footer-logo-host {
  margin-right: 10px;
}

.hosted-by > div {
  width: 200px;
}

@media only screen and (max-width: 650px) {
  .footer-border {
    display: flex;
    flex-direction: column;
    width: 95%;
    border-top: solid 1px white;
  }
  
  .copyright {
    display: flex;
    align-self: center;
    margin-bottom: 1rem;
  }
  
  .hosted-by {
    display: flex;
    align-self: center;
  }
}