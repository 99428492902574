  .form-public {
    display: flex;
    flex-direction: row;
    float: left;
    font-family: Avenir, Arial, Helvetica, sans-serif;        
    padding: 2rem;
    background-color: white;
    border-radius: 5px;
  }

  .form-wrapper-public {
    background-color: rgb(244, 244, 244);
    color: #4E4E4E;
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-radius: 5px;
    width: 600px; 
  }

  .form-header-public {
    padding: 0.8rem;
    margin-bottom: 1em;
    /* background: linear-gradient(#fb4410,#c2112f); */
    background: white;
    color: #4E4E4E;
    font-weight: bold;
    border-radius: 5px 5px 0 0;
    border-bottom: solid 1px #cdccdf;
  }

  .form-header-public > label {
    padding-left: 2.5em;
  }

  @media (max-width: 900px) {
    .form-public {
      flex-direction: column;
    }
    .form-wrapper-public {
      width: 400px;
    }
  }

  @media (max-width: 600px) {
    .form-public {
      flex-direction: column;
    }
    .form-wrapper-public {
      width: auto;
    }
  }