.navbar {
  display: flex;
  justify-content: space-between;
  color: #4E4E4E;
  font-family: Avenir, Arial, Helvetica, sans-serif;
  /* min-width: 400px;  */
  width: auto !important; 
  align-items: center;
  padding: 5px 5px 5px 5px;
  margin: 20px 50px 20px 50px;  
}

.navbar > .banner {
 
}

.navbar > .menu-items {
  display: flex;
  flex-direction: row;
  font-weight: bold;
}

.navbar > button { 
  cursor: pointer;
}

.menu-item {
  cursor: pointer;
  /* padding: 0 10px 0 0; */
}

.menu-item:hover {
  text-decoration: underline;
}

.menu-divider {
  color: #9D9D9D;
  padding: 0px 10px 0 10px;
}